export default () => ({
  numero: "",
  googleContactEmail: "",
  credenciales: {
    clientId: "",
    clientSecret: "",
    access_token: "",
    refresh_token: "",
    scope: "",
    token_type: "",
    id_token: "",
    expiry_date: "",
  },
  agenteId: "",
});
